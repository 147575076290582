<template>
  <div class="add-form">
    <el-form :model="nsForm" ref="nsForm" size="small" label-position="top" :rules="nsRules">
      <el-form-item :label="$t('location')" required>
        <checkout-list :data.sync="locationList" />
      </el-form-item>

      <el-form-item :label="$t('namespaceName')" prop="metadata.name">
        <el-input v-model.trim="nsForm.metadata.name"></el-input>
        <span class="help">{{ $t("tips.nameCommonPattern") }}</span>
      </el-form-item>

      <el-form-item :label="$t('cpuQuotaLimits')">
        <el-input v-model="nsForm.spec.hard.cpu">
          <template slot="prepend">{{ $t("quota") }}</template>
          <span slot="append">Core</span>
        </el-input>
        <span class="help">{{ $t("cpuQuotaLimitsTips") }}</span>
      </el-form-item>

      <el-form-item :label="$t('memQuotaLimits')">
        <el-input v-model="nsForm.spec.hard.memory">
          <template slot="prepend">{{ $t("quota") }}</template>
          <span slot="append">G</span>
        </el-input>
        <span class="help">{{ $t("memQuotaLimitsTips") }}</span>
      </el-form-item>

      <el-form-item v-for="item in nsForm.spec.limits" :key="item.type">
        <div slot="label">
          {{ item.type }} {{ $t("resourceLimits") }}
          <span class="help" v-if="item.type === 'Pod'">
            {{ $t("podResourceLimitsTips") }}
          </span>

          <span class="help" v-if="item.type === 'Container'">
            {{ $t("containerResourceLimitsTips") }}
          </span>
        </div>

        <el-row :gutter="20" style="margin-bottom: 10px;">
          <el-col :span="12">
            <el-input v-model="item.max.cpu">
              <template slot="prepend">
                {{ $t("cpuQuotaUpperLimit") }}
              </template>

              <el-select v-model="item.max.cpuUnit" slot="append" style="width: 90px;">
                <el-option label="Core" value="Core"></el-option>
                <el-option label="m" value="m"></el-option>
              </el-select>
            </el-input>
          </el-col>

          <el-col :span="12">
            <el-input v-model="item.max.memory">
              <template slot="prepend">
                {{ $t("memQuotaUpperLimit") }}
              </template>

              <el-select v-model="item.max.memoryUnit" slot="append" style="width: 90px;">
                <el-option label="K" value="K"></el-option>
                <el-option label="M" value="M"></el-option>
                <el-option label="G" value="G"></el-option>
              </el-select>
            </el-input>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-bottom: 10px;">
          <el-col :span="12">
            <el-input v-model="item.min.cpu">
              <template slot="prepend">
                {{ $t("cpuQuotaLowerLimit") }}
              </template>

              <el-select v-model="item.min.cpuUnit" slot="append" style="width: 90px;">
                <el-option label="Core" value="Core"></el-option>
                <el-option label="m" value="m"></el-option>
              </el-select>
            </el-input>
          </el-col>

          <el-col :span="12">
            <el-input v-model="item.min.memory">
              <template slot="prepend">
                {{ $t("memQuotaLowerLimit") }}
              </template>

              <el-select v-model="item.min.memoryUnit" slot="append" style="width: 90px;">
                <el-option label="K" value="K"></el-option>
                <el-option label="M" value="M"></el-option>
                <el-option label="G" value="G"></el-option>
              </el-select>
            </el-input>
          </el-col>
        </el-row>

        <el-row :gutter="20" v-if="item.type == 'Container'">
          <el-col :span="12">
            <el-input v-model="item.default.cpu">
              <template slot="prepend">
                {{ $t("cpuQuotaDefault") }}
              </template>

              <el-select v-model="item.default.cpuUnit" slot="append" style="width: 90px;">
                <el-option label="Core" value="Core"></el-option>
                <el-option label="m" value="m"></el-option>
              </el-select>
            </el-input>
          </el-col>

          <el-col :span="12">
            <el-input v-model="item.default.memory">
              <template slot="prepend">
                {{ $t("memQuotaDefault") }}
              </template>

              <el-select v-model="item.default.memoryUnit" slot="append" style="width: 90px;">
                <el-option label="K" value="K"></el-option>
                <el-option label="M" value="M"></el-option>
                <el-option label="G" value="G"></el-option>
              </el-select>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item :label="$t('tag')">
        <add-labels :labels.sync="nsForm.metadata.labels" />
        <span class="help">{{ $t("tips.addlabels") }}</span>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="nsSubmit" size="small" plain :loading="loading">
          {{ $t("handle.submit") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import CheckoutList from "@/components/CheckoutList";
import { namespaceSubmit, resourceQuotaSubmit, limitrangesSubmit } from "api/namespace";

import { includes } from "lodash";

export default {
  components: {
    CheckoutList
  },

  data() {
    return {
      nsForm: {
        metadata: {
          name: "",
          labels: {}
        },

        spec: {
          // resource quota
          hard: {
            cpu: 20,
            memory: "40"
          },

          limits: [
            {
              type: "Pod",
              max: {
                cpu: 4,
                memory: 8,
                cpuUnit: "Core",
                memoryUnit: "G"
              },
              min: {
                cpu: 0.01,
                memory: 64,
                cpuUnit: "Core",
                memoryUnit: "M"
              }
            },

            {
              type: "Container",
              max: {
                cpu: 4,
                memory: 8,
                cpuUnit: "Core",
                memoryUnit: "G"
              },
              min: {
                cpu: 0.01,
                memory: 64,
                cpuUnit: "Core",
                memoryUnit: "M"
              },
              default: {
                cpu: 1,
                memory: 2,
                cpuUnit: "Core",
                memoryUnit: "G"
              }
            }
          ]
        }
      },
      nsRules: {
        "metadata.name": [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          {
            pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
            message: this.$t("rules.pattern"),
            trigger: "blur"
          }
        ]
      },
      locationList: [],
      loading: false
    };
  },

  computed: {
    vendor() {
      return this.locationList[0];
    },

    region() {
      return this.locationList[1];
    },

    cluster() {
      return this.locationList[2];
    }
  },

  methods: {
    nsSubmit() {
      this.$refs["nsForm"].validate(valid => {
        if (valid) {
          this.loading = true;
          namespaceSubmit(
            { metadata: this.nsForm.metadata },
            { vendor: this.vendor, region: this.region, cluster: this.cluster }
          ).then(response => {
            this.loading = false;
            if (response.code === 0) {
              this.resourceQuotaSubmit();
              this.limitsRange();
              this.$router.push({ path: `/list/Namespace` });
            }
          });
        }
      });
    },

    resourceQuotaSubmit() {
      if (this.nsForm.spec.hard.memory) this.nsForm.spec.hard.memory += "G";

      resourceQuotaSubmit(
        {
          metadata: {
            name: this.nsForm.metadata.name,
            namespace: this.nsForm.metadata.name
          },

          spec: {
            hard: this.nsForm.spec.hard
          }
        },
        { vendor: this.vendor, region: this.region, cluster: this.cluster }
      );
    },

    limitsRange() {
      this.nsForm.spec.limits.forEach(item => {
        if (item.max.cpu && !includes(item.max.cpu, item.max.cpuUnit) && item.max.cpuUnit != "Core")
          item.max.cpu += item.max.cpuUnit;

        if (item.max.memory && !includes(item.max.memory, item.max.memoryUnit)) item.max.memory += item.max.memoryUnit;

        if (item.min.cpu && !includes(item.min.cpu, item.min.cpuUnit) && item.min.cpuUnit != "Core")
          item.min.cpu += item.min.cpuUnit;

        if (item.min.memory && !includes(item.min.memory, item.min.memoryUnit)) item.min.memory += item.min.memoryUnit;

        if (item.default) {
          if (item.default.cpu && !includes(item.default.cpu, item.default.cpuUnit) && item.default.cpuUnit != "Core")
            item.default.cpu += item.default.cpuUnit;
          if (item.default.memory && !includes(item.default.memory, item.default.memoryUnit))
            item.default.memory += item.default.memoryUnit;

          delete item.default.cpuUnit;
          delete item.default.memoryUnit;
        }

        delete item.max.cpuUnit;
        delete item.max.memoryUnit;

        delete item.min.cpuUnit;
        delete item.min.memoryUnit;
      });

      limitrangesSubmit(
        {
          metadata: {
            name: this.nsForm.metadata.name,
            namespace: this.nsForm.metadata.name
          },

          spec: {
            limits: this.nsForm.spec.limits
          }
        },
        { vendor: this.vendor, region: this.region, cluster: this.cluster }
      );
    }
  }
};
</script>
